export function formatDoctorsObject(doctorsInfo) {
	return doctorsInfo.map((doctor) => {
		var name = `${doctor.firstName} ${doctor.lastName}`;
		return { label: name, value: doctor._id };
	});
}
export function getDefaultDoctor(
	formattedDoctors,
	defaultDoctor
) {
	if (formattedDoctors.length >= 1) {
		let selectedDoctor = formattedDoctors.find(
			(doctor) => doctor.label === defaultDoctor
		);

		return selectedDoctor.value;
	}
	return '';

	// return formattedDoctors.filter((doctor) => {
	// 	doctor.label.includes(defaultDoctor);
	// });
}
