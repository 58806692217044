import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAppointmentModal } from '../../redux';
import {
	Modal,
	Button,
	Form,
	Input,
	Row,
	Col,
	Card,
	message,
} from 'antd';
import Spinner from '../Spinners/Spinner';
import NewAppointmentForm from './NewAppointmentForm';
import ExistingAppointment from './ExistingAppointment';
import Api from '../../helpers/api';
import {
	formatDoctorsObject,
	getDefaultDoctor,
} from './utils';
var empty = require('is-empty');

export default function AppointmentModal({
	// visibility,
	// setModalVisibility,
	slotInfo,

	gatData,
}) {
	const [formattedDoctorsList, setFormattedDoctorsList] =
		useState([]);
	const [defaultDoctor, setDefaultDoctorState] =
		useState('');
	const modalVisibility = useSelector(
		(state) => state.appointments.modalIsVisible
	);
	const dispatch = useDispatch();
	const onModalClose = () => {
		dispatch(toggleAppointmentModal());
		// setModalVisibility(!visibility);
		gatData();
	};

	function getDoctors() {
		const api = new Api();
		api
			.init()
			.get('/protected/users/doctors')
			.then((doctorsInfo) => {
				if (!empty(doctorsInfo)) {
					let formattedDoctors = formatDoctorsObject(
						doctorsInfo.data
					);
					setFormattedDoctorsList(formattedDoctors);
				} else {
					message.error(
						'No Doctors found. Please add a Doctor in the Database'
					);
				}
			})
			.catch((err) => {
				console.log(err);
				message.error(
					'There was some error getting all Doctor Information. Please try again later'
				);
			});
	}
	useEffect(() => {
		getDoctors();
	}, []);
	useEffect(() => {
		let calculatedDefaultDoctor;
		if (slotInfo.isNew) {
			calculatedDefaultDoctor = getDefaultDoctor(
				formattedDoctorsList,
				'Akshata Shenvi'
			);
			setDefaultDoctorState(calculatedDefaultDoctor);
		} else {
			setDefaultDoctorState(slotInfo.userId);
		}
	}, [formattedDoctorsList, slotInfo]);
	return (
		<Modal
			title='Appointment'
			destroyOnClose
			width={650}
			visible={modalVisibility}
			footer={null}
			onCancel={onModalClose}>
			{slotInfo.isNew ? (
				<NewAppointmentForm
					appointmentInfo={slotInfo}
					onModalClose={onModalClose}
				/>
			) : defaultDoctor === '' ? (
				<Spinner />
			) : (
				<ExistingAppointment
					slotInfo={slotInfo}
					onModalClose={onModalClose}
					formattedDoctorsList={formattedDoctorsList}
					defaultDoctor={defaultDoctor}
				/>
			)}
		</Modal>
	);
}
