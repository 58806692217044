import React, {
	useLayoutEffect,
	useState,
	useRef,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import _ from 'lodash';
import {
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	ListItemButton,
	Typography,
} from '@mui/material';
import Spinner from '../Spinners/Spinner';
export default function ExistingUserList({
	userList,
	selectedItem,
	setSelectedItem,
	setModalOpen,
	setSelectedId,
}) {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(700);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const parentRef = useRef(null);

	useLayoutEffect(() => {
		if (parentRef.current) {
			setWidth(parentRef.current.offsetWidth);
			setHeight(parentRef.current.offsetHeight);
		}
	}, []);

	const handleListItemClick = (event, index) => {
		for (let i = 0; i < userList.length; i++) {
			if (i === index) {
				const userId = userList[i]._id;
				const firstName = userList[i].firstName;
				const lastName = userList[i].lastName;
				const emailId = userList[i].emailId;
				const roles = userList[i].roles;
				const assignedColor = userList[i].assignedColor;
				const phoneNumber = userList[i].phoneNumber;

				setSelectedItem({
					firstName: firstName,
					lastName: lastName,
					phoneNumber: phoneNumber,
					emailId: emailId,
					roles: roles,
					assignedColor: assignedColor,
				});
				setSelectedId(userId);
				setModalOpen(true);
			}
		}
		setSelectedIndex(index);
	};
	function getInitial(roles) {
		if (roles.includes('Admin')) {
			return 'A';
		}
		if (roles.includes('Doctor')) {
			return 'D';
		}
		return 'S';
	}
	return (
		<div ref={parentRef}>
			<Scrollbars
				style={{
					width: width,
					height: height,
				}}>
				<List>
					{userList.length > 0 ? (
						userList.map((user, index) => (
							<ListItemButton
								key={index}
								selected={selectedIndex === index}
								onClick={(event) =>
									handleListItemClick(event, index)
								}>
								<ListItem
									alignItems='flex-start'
									style={{
										backgroundColor: user.assignedColor,
									}}>
									<ListItemAvatar>
										<Avatar>
											{getInitial(user.roles)}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={`${user.firstName} ${user.lastName}`}
										secondary={
											<React.Fragment>
												<Typography
													sx={{ display: 'inline' }}
													component='span'
													variant='body2'
													color='text.primary'>
													{_.isEmpty(user.emailId)
														? 'No email'
														: user.emailId}
												</Typography>
												{' | ' + user.roles.join(', ')}
											</React.Fragment>
										}
									/>
								</ListItem>
							</ListItemButton>
						))
					) : (
						<Spinner />
					)}
				</List>
			</Scrollbars>
		</div>
	);
}
