import React from 'react';
import {
	Grid,
	makeStyles,
	Paper,
	Button,
	ButtonGroup,
	FormLabel,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
} from '@material-ui/core';
import { Icon } from '@iconify/react';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

export default function MilkTeeth({
	milkTeeth,
	setMilkTeeth,
}) {
	const classes = useStyles();

	const quadrants = [
		{
			name: 'Upper Right',
			val: [55, 54, 53, 52, 51],
		},
		{
			name: 'Upper Left',
			val: [61, 62, 63, 64, 65],
		},
		{
			name: 'Lower Right',
			val: [85, 84, 83, 82, 81],
		},
		{
			name: 'Lower Left',
			val: [71, 72, 73, 74, 75],
		},
	];
	const toothSelected = (toothNumber, value) => {
		setMilkTeeth({
			...milkTeeth,
			[toothNumber]: value,
		});
	};
	return (
		<Grid container spacing={3}>
			{quadrants.map((quadrant) => (
				<Grid item xs={6} key={quadrant.name}>
					<Paper
						className={classes.paper}
						style={{ backgroundColor: '#D3D3D3' }}>
						<FormControl
							component='fieldset'
							sx={{ m: 3 }}
							variant='filled'>
							<FormLabel component='legend'>
								{quadrant.name}
							</FormLabel>
							<FormGroup
								row
								style={{ padding: '0px', margin: '0px' }}>
								{quadrant.val.map((toothNumber) => (
									<FormControlLabel
										key={toothNumber}
										label={toothNumber}
										labelPlacement='bottom'
										control={
											<Checkbox
												size='medium'
												checked={milkTeeth[toothNumber]}
												onChange={(e) => {
													toothSelected(
														toothNumber,
														e.target.checked
													);
												}}
												icon={
													<Icon
														icon='mdi:tooth'
														color='white'
													/>
												}
												checkedIcon={
													<Icon icon='mdi:tooth' />
												}
											/>
										}
									/>
								))}
							</FormGroup>
						</FormControl>{' '}
					</Paper>
				</Grid>
			))}
			{/* <Grid item xs={6}>
				<Paper className={classes.paper}>
					<fieldset>
						<legend>Upper Right</legend>
						<ButtonGroup
							size='small'
							color='primary'
							aria-label='outlined primary button group'>
							<Button>55</Button>
							<Button>54</Button>
							<Button>53</Button>
							<Button>52</Button>
							<Button>51</Button>
						</ButtonGroup>
					</fieldset>
				</Paper>
			</Grid>

			<Grid item xs={6}>
				<Paper className={classes.paper}>
					<fieldset>
						<legend>Upper Left</legend>
						<ButtonGroup
							size='small'
							color='primary'
							aria-label='outlined primary button group'>
							<Button>61</Button>
							<Button>62</Button>
							<Button>63</Button>
							<Button>64</Button>
							<Button>65</Button>
						</ButtonGroup>
					</fieldset>
				</Paper>
			</Grid>

			<Grid item xs={6}>
				<Paper className={classes.paper}>
					<fieldset>
						<legend>Lower Right</legend>
						<ButtonGroup
							size='small'
							color='primary'
							aria-label='outlined primary button group'>
							<Button>85</Button>
							<Button>84</Button>
							<Button>83</Button>
							<Button>82</Button>
							<Button>81</Button>
						</ButtonGroup>
					</fieldset>
				</Paper>
			</Grid>

			<Grid item xs={6}>
				<Paper className={classes.paper}>
					<fieldset>
						<legend>Lower Left</legend>
						<ButtonGroup
							size='small'
							color='primary'
							aria-label='outlined primary button group'>
							<Button>71</Button>
							<Button>72</Button>
							<Button>73</Button>
							<Button>74</Button>
							<Button>75</Button>
						</ButtonGroup>
					</fieldset>
				</Paper>
			</Grid> */}
		</Grid>
	);
}
