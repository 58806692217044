import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { List, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
export default function PatientList({
	searchedPatients,
	patientDetails,
	setPatientDetails,

	next,
}) {
	if (searchedPatients.length === 0) {
		return <div>Patient Not Found</div>;
	} else {
		return (
			<Scrollbars style={{ width: 500, height: 300 }}>
				{
					<List
						itemLayout='horizontal'
						dataSource={searchedPatients}
						renderItem={(item) => (
							<List.Item
								actions={[
									<Button
										onClick={() => {
											setPatientDetails({
												...patientDetails,
												fName: item.patientFirstName,
												lName: item.patientLastName,
												phoneNumber: item.phoneNumber,
												patient_id: item._id,
											});
											next(1);
										}}>
										Select
									</Button>,
								]}>
								<List.Item.Meta
									avatar={
										<Avatar
											style={{
												backgroundColor: '#f56a00',
											}}>
											{item.patientFirstName[0]}
											{item.patientLastName[0]}
										</Avatar>
									}
									title={`${item.patientFirstName} ${item.patientLastName}`}
									description={`${item.phoneNumber}`}
								/>
							</List.Item>
						)}
					/>
				}
			</Scrollbars>
		);
	}
}
