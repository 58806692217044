import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { Row, Col } from 'antd';

import Calander from '../Calander/Calander';

const Home = ({ history }) => {
	return (
		<Grid container style={{ marginTop: '1rem' }}>
			<Grid item xs={12}></Grid>
			<Grid item xs={12}>
				<Calander history={history} />
			</Grid>
			<Grid item xs={12}></Grid>
		</Grid>
	);
	//   <Row style={{ height: "10rem" }}>
	// <Col span={4} style={{ backgroundColor: "blue" }}>col-6</Col>
	//   <Col span={16} ></Col>

	//   <Col span={4} style={{ backgroundColor: "blue" }}>col-6</Col>
	// </Row>
};

export default Home;
