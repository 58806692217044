import React, { useEffect, useState } from 'react';
import DatePicker from '../CustomComponents/DatePickerDay';
import {
	Button,
	Form,
	Input,
	Typography,
	message,
	Popconfirm,
	Select,
} from 'antd';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
// import dayjs from 'dayjs';
import Api from '../../helpers/api';
import { convertDateForAntD } from '../../helpers/helpers';

// var utc = require('dayjs/plugin/utc');
var empty = require('is-empty');
// dayjs.extend(utc);

export default function ExistingAppointment({
	slotInfo,
	onModalClose,
	formattedDoctorsList,
	defaultDoctor,
}) {
	const { Title } = Typography;
	const [ids, set_ids] = useState({
		appointment_id: '',
		patient_id: '',
	});

	useEffect(() => {
		if (slotInfo !== '') {
			set_ids({
				appointment_id: slotInfo._id,
				patient_id: slotInfo.patient_Id,
			});
		}
	}, [slotInfo]);
	const onFinish = (values) => {
		if (!empty(ids)) {
			if (values['Start Date'] >= values['End Date']) {
				message.warning(
					'Start Date cannot be later than End Date'
				);
			} else {
				const api = new Api();
				api
					.init()
					.patch(
						'/protected/appointments/updateappointment',
						{
							appointmentId: ids.appointment_id,
							appointmentStartDate: values['Start Date'],
							appointmentEndDate: values['End Date'],
							userId: values['Doctor'],
						}
					)
					.then((responseData) => {
						if (
							responseData &&
							responseData.status === 200
						) {
							message.success(
								'Appointment updated successfully'
							);
							onModalClose();
						} else {
							message.error('Unable to update Appointment');
							onModalClose();
						}
					})
					.catch((err) => {
						if (err.response.data.errList.message) {
							console.log(
								err.response.data.errList.message
							);
							message.error(
								err.response.data.errList.message
							);
							onModalClose();
						} else {
							message.error('Unable to update Appointment');
							onModalClose();
						}
					});
			}
		} else {
			message.error('Unable to update Appointment');
			onModalClose();
		}
	};
	const deleteAppointment = () => {
		if (!empty(ids)) {
			const api = new Api();
			api
				.init()
				.delete(
					'/protected/appointments/deleteappointment',
					{
						params: { appointmentId: ids.appointment_id },
					}
				)
				.then((responseData) => {
					if (responseData && responseData.status === 200) {
						message.success(
							'Appointment deleted successfully'
						);
						onModalClose();
					} else {
						message.error('Unable to delete Appointment');
						onModalClose();
					}
				})
				.catch((err) => {
					if (err.response.data.errList.message) {
						console.log(err.response.data.errList.message);
						message.error(
							err.response.data.errList.message
						);
						onModalClose();
					} else {
						message.error('Unable to delete Appointment');
						onModalClose();
					}
				});
		} else {
			message.error(
				'There was an error in deleting the appointment. Please try again later'
			);
			onModalClose();
		}
	};
	const onFinishFailed = (errorInfo) => {};

	return (
		<Form
			layout='horizontal'
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14, offset: 1 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			initialValues={{
				// 'First Name': slotInfo.PatientFirstName,
				// 'Last Name': slotInfo.PatientLastName,

				'Start Date': convertDateForAntD(
					slotInfo.startDate
				),
				'End Date': convertDateForAntD(slotInfo.endDate),
				Doctor: defaultDoctor,
			}}>
			<Title level={4}>Edit Appointment</Title>
			<Form.Item label='Name'>
				<Title level={5}>
					<Link
						component={RouterLink}
						to={`/patient/${ids.patient_id}`}
						target='_blank'
						color='secondary'>
						{slotInfo.PatientFirstName}{' '}
						{slotInfo.PatientLastName}
					</Link>
				</Title>
			</Form.Item>

			{/* <Form.Item
				name='First Name'
				label='First Name'
				rules={[
					{
						required: true,
						message: 'First Name cannot be Empty',
					},
				]}>
				<Input allowClear disabled />
			</Form.Item>
			<Form.Item
				name='Last Name'
				label='Last Name'
				rules={[
					{
						required: true,
						message: 'Last Name cannot be Empty',
					},
				]}>
				<Input allowClear disabled />
			</Form.Item> */}

			<Form.Item
				name='Start Date'
				label='Start Date'
				rules={[
					{
						required: true,
						message: 'Start Date cannot be Empty',
					},
				]}>
				<DatePicker showTime />
			</Form.Item>
			<Form.Item
				name='End Date'
				label='End Date'
				rules={[
					{
						required: true,
						message: 'End Date cannot be Empty',
					},
				]}>
				<DatePicker showTime />
			</Form.Item>
			<Form.Item name='Doctor' label='Doctor' rules={[]}>
				<Select
					// defaultValue={defaultDoctor}
					options={formattedDoctorsList}
				/>
			</Form.Item>
			<Form.Item wrapperCol={{ offset: 3, span: 16 }}>
				<Button
					className='search-button'
					type='primary'
					htmlType='submit'>
					Save
				</Button>
				<Popconfirm
					title='Are you sure？'
					okText='Yes'
					cancelText='No'
					onConfirm={deleteAppointment}>
					<Button htmlType='button' danger>
						Delete
					</Button>
				</Popconfirm>
			</Form.Item>
		</Form>
	);
}
