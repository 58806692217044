import {
	TOGGLE_APPOINTMENT_MODAL,
	CHANGE_CALENDAR_VIEW,
	GET_CALENDAR_DATA,
	APPOINTMENT_DATA_ERROR,
} from './appointmentTypes';

import Api from '../../helpers/api';

export const toggleAppointmentModal = () => {
	return {
		type: TOGGLE_APPOINTMENT_MODAL,
	};
};

export const changeCalendarView = (view) => {
	return {
		type: CHANGE_CALENDAR_VIEW,
		payload: view,
	};
};

export const toggleDataError = (message = '') => {
	return {
		type: APPOINTMENT_DATA_ERROR,
		payload: message,
	};
};

export const getCalendarData = ({
	startDate,
	endDate,
	history,
}) => {
	// const { startDate, endDate } = dates;
	return async function getCalendarDataThunk(
		dispatch,
		getState
	) {
		const api = new Api();
		try {
			const response = await api
				.init()
				.get('/protected/appointments/getappointments', {
					params: {
						startdate: startDate,
						enddate: endDate,
					},
				});

			if (response.data) {
				dispatch({
					type: GET_CALENDAR_DATA,
					payload: response.data.map((item) => ({
						id: item._id,
						patient_Id: item.patientId,
						startDate: new Date(
							item.appointmentStartDateTime
						),
						// .toLocaleString('en-US', {
						// timeZone: 'Asia/Kolkata',
						// })
						endDate: new Date(item.appointmentEndDateTime),
						// .toLocaleString('en-US', {
						// timeZone: 'Asia/Kolkata',
						// })
						title: `${item.patientFirstName}  ${item.patientLastName}`,
						userId: item.userId,
						doctorFirstName: item.firstName,
						doctorLastName: item.lastName,
						assignedColor: item.assignedColor,
					})),
				});
			} else {
				dispatch({
					type: GET_CALENDAR_DATA,
					payload: [],
				});
			}
		} catch (error) {
			history.push('/login');
			console.log(error);
			return dispatch(
				toggleDataError(
					'An error occured while loading appointments'
				)
			);
		}
	};

	// .then((response) => {
	//   console.log(response)
	// 	if (response.data) {
	// 		return {
	// 			type: GET_CALENDAR_DATA,
	// 			payload: response.data,
	// 		};
	// 	} else {
	// 		return {
	// 			type: GET_CALENDAR_DATA,
	// 			payload: [],
	// 		};
	// 	}
	// })
	// .catch((error) => {
	// 	console.log(error);
	// 	return toggleDataError(
	// 		'An error occured while loading appointments'
	// 	);
	// });
};
