import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Card,
	Button,
	Typography,
	message,
	Empty,
	Modal,
} from 'antd';
import _ from 'lodash';
import { ADMIN, STAFF, DOCTOR } from '../Constants';
import Api from '../../helpers/api';
import { SliderPicker } from 'react-color';

export default function UpdateUsersFormModal({
	selectedItem,
	openModal,
	setModalOpen,
	setSelectedItem,
	selectedId,
}) {
	const [form] = Form.useForm();
	const { Option } = Select;
	const [color, setColor] = useState('#000');

	function validationErrors(values) {
		if (_.isEmpty(values['firstName'])) {
			message.error('First name Cannot be empty');
		}
		if (_.isEmpty(values['lastName'])) {
			message.error('Last name Cannot be empty');
		}
		if (_.isEmpty(values['phoneNumber'])) {
			message.error('Phone Number Cannot be empty');
		}
		if (_.isEmpty(values['emailId'])) {
			message.error('Email Cannot be empty');
		}
		if (_.isEmpty(values['roles'])) {
			message.error('Roles Cannot be empty');
		}
		if (_.isEmpty(values['assignedColor'])) {
			message.error('Color Cannot be empty');
		}
	}

	function onSubmit(values) {
		if (
			!_.isEmpty(values['firstName']) &&
			!_.isEmpty(values['lastName']) &&
			!_.isEmpty(values['phoneNumber']) &&
			!_.isEmpty(values['emailId']) &&
			!_.isEmpty(values['roles'])
		) {
			const roles = values['roles'];
			const api = new Api();
			console.log(color);
			api
				.init()
				.patch(
					'/protected/users/user',
					{
						firstName: values['firstName'],
						lastName: values['lastName'],
						phoneNumber: values['phoneNumber'],
						emailId: values['emailId'],
						roles: roles,
						permission: ['All'],
						assignedColor: color,
					},
					{
						params: {
							userid: selectedId,
						},
					}
				)
				.then((userDetails) => {
					if (_.isEmpty(userDetails)) {
						message.error(
							'There was an error updating user. Please try again later.'
						);
					} else {
						message.success(
							`Successfully updated user ${userDetails.firstName}`
						);
					}
				})
				.catch((err) => {
					console.log(err);
					message.error(
						'There was an error updating user. Please try again later.'
					);
				});
		} else {
			validationErrors(values);
		}
	}
	const tailLayout = {
		wrapperCol: { offset: 8, span: 16 },
	};

	useEffect(() => {
		if (!_.isEmpty(selectedItem)) {
			form.setFieldsValue(selectedItem);
			setColor(selectedItem.assignedColor);
		}
	}, [selectedItem]);

	function getRoleOptions() {
		const rolesAvailable = [ADMIN, STAFF, DOCTOR];
		return rolesAvailable.map((role) => {
			return { label: role, value: role };
		});
	}
	return (
		<Modal
			open={openModal}
			destroyOnClose={true}
			onCancel={() => {
				setSelectedItem({});
				form.resetFields();
				setModalOpen(false);
			}}
			footer={null}>
			<Form
				preserve={false}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 8 }}
				form={form}
				initialValues={selectedItem}
				onFinish={onSubmit}>
				<Form.Item
					label='First Name'
					name='firstName'
					required>
					<Input />
				</Form.Item>
				<Form.Item
					label='Last Name'
					name='lastName'
					required>
					<Input />
				</Form.Item>
				<Form.Item
					label='Phone Number'
					name='phoneNumber'
					required>
					<Input />
				</Form.Item>
				<Form.Item label='E-mail' name='emailId' required>
					<Input />
				</Form.Item>

				<Form.Item label='Roles' name='roles' required>
					<Select
						mode='multiple'
						allowClear
						options={getRoleOptions()}
					/>
				</Form.Item>
				<Form.Item
					label='Color'
					name='assignedColor'
					// value={color.hex}
					required>
					<SliderPicker
						color={color}
						onChangeComplete={(color) => {
							setColor(color.hex);
						}}
					/>
				</Form.Item>
				<Form.Item label='Selected Color'>
					<div
						style={{
							width: '20px',
							height: '20px',
							backgroundColor: color,
						}}
					/>
				</Form.Item>
				<Form.Item {...tailLayout}>
					<Button type='primary' htmlType='submit'>
						Update
					</Button>
				</Form.Item>
			</Form>
			{/* )} */}
		</Modal>
	);
}
