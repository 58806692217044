import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import AppContainer from './AppContainer';
import 'antd/dist/antd.css';
import reportWebVitals from './reportWebVitals';
import { Routes, renderRoutes } from './routes/routes';
import { ConfigProvider } from 'antd';
import { CookiesProvider } from 'react-cookie';
import store from './redux/store';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ConfigProvider>
				<CookiesProvider>{renderRoutes()}</CookiesProvider>
			</ConfigProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
