import React, { useState } from 'react';
import {
	Tabs,
	Input,
	Form,
	Button,
	message,
	Typography,
	Tag,
	Popconfirm,
} from 'antd';
import { Paper, Grid, makeStyles } from '@material-ui/core';
import PermanentTeeth from './PermanentTeeth';
import MilkTeeth from './MilkTeeth';
import Api from '../../helpers/api';

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

export default function PatientDiagnosis({
	openAppointment,
	defaultPermanentTeeth,
	defaultMilkTeeth,
}) {
	const { TabPane } = Tabs;
	const { Title } = Typography;
	const [currentTab, setCurrentTab] = useState('1');
	const [milkTeeth, setMilkTeeth] = useState(
		defaultMilkTeeth
	);
	const [permanentTeeth, setPermanentTeeth] = useState(
		defaultPermanentTeeth
	);
	const [diagnosis, setDiagnosis] = useState(
		openAppointment.appointmentDiagnosis
	);

	const [form] = Form.useForm();
	const onFinish = (values) => {
		let finalDiagnosString = `${values['Diagnosis Text']}:- `;
		let permanentTeethString = '';
		let milkTeethString = '';
		for (const [key, value] of Object.entries(
			permanentTeeth
		)) {
			if (value) {
				if (permanentTeethString === '') {
					permanentTeethString += String(key);
				} else {
					permanentTeethString += ',' + String(key);
				}
			}
		}
		for (const [key, value] of Object.entries(milkTeeth)) {
			if (value) {
				if (milkTeethString === '') {
					milkTeethString += String(key);
				} else {
					milkTeethString += ',' + String(key);
				}
			}
		}
		if (permanentTeethString !== '') {
			finalDiagnosString += permanentTeethString;
		}
		if (milkTeethString !== '') {
			if (permanentTeethString !== '') {
				finalDiagnosString += ',';
			}
			finalDiagnosString += milkTeethString;
		}
		if (
			milkTeethString === '' &&
			permanentTeethString === ''
		) {
			finalDiagnosString = finalDiagnosString.replace(
				':- ',
				' '
			);
		}
		setDiagnosis([...diagnosis, finalDiagnosString]);
		form.resetFields();
		setPermanentTeeth(defaultPermanentTeeth);
		setMilkTeeth(defaultMilkTeeth);
	};
	const onFinishFailed = (errorInfo) => {
		message.error(errorInfo.errorFields[0].errors[0]);
	};
	const confirmDiagnosis = () => {
		const api = new Api();
		api
			.init()
			.patch(
				'/protected/appointments/updateappointmentdiagnosis',
				{
					appointmentId: openAppointment._id,
					diagnosis: diagnosis,
				}
			)
			.then((responseData) => {
				if (responseData && responseData.status === 200) {
					message.success('Diagnosis Updated Successfully');
				} else {
					message.error('Error Updating Diagnosis');
				}
			});
	};
	const classes = useStyles();
	return (
		<Grid>
			<Title level={2}>Patient Appointment Diagnosis</Title>
			<Paper elevation={3} className={classes.paper}>
				{diagnosis.length === 0 ? (
					<Typography>
						No Diagnosis Added to Appointment
					</Typography>
				) : (
					diagnosis.map((element) => (
						<Tag
							key={element}
							color='magenta'
							closable
							onClose={(e) => {
								setDiagnosis(
									diagnosis.filter(function (item) {
										return item !== element;
									})
								);
							}}>
							{element}
						</Tag>
					))
				)}
				<Popconfirm
					title='Are you sure you want to save Diagnosis'
					onConfirm={confirmDiagnosis}>
					<Button type='primary'>Save</Button>
				</Popconfirm>
			</Paper>

			<Tabs
				tabPosition='left'
				onChange={(key) => {
					setCurrentTab(key);
				}}>
				<TabPane tab='Permanent Teeth' key='1'>
					<PermanentTeeth
						permanentTeeth={permanentTeeth}
						setPermanentTeeth={setPermanentTeeth}
					/>
				</TabPane>
				<TabPane tab='Milk Teeth' key='2'>
					<MilkTeeth
						milkTeeth={milkTeeth}
						setMilkTeeth={setMilkTeeth}
					/>
				</TabPane>
			</Tabs>
			<Grid style={{ marginTop: '1rem' }}>
				<Form
					form={form}
					layout='inline'
					initialValues={{ 'Diagnosis Text': '' }}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 14 }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name='Diagnosis Text'
						label='Diagnosis Name'
						rules={[
							{
								required: true,
								message: 'Diagnosis Text cannot be Empty',
							},
						]}>
						<Input
							placeholder='Diagnosis Text'
							style={{ width: '20rem' }}
						/>
					</Form.Item>
					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Add
						</Button>
					</Form.Item>
				</Form>
			</Grid>
		</Grid>
	);
}
