import React, { useEffect, useState } from 'react';
import AddNewUser from './AddNewUser';
import { Tabs } from 'antd';
import UpdateUsers from './UpdateUsers';

export default function Users() {
	const pages = [
		{
			key: '1',
			label: 'Add Users',
			children: <AddNewUser />,
		},
		{
			key: '2',
			label: 'Update Users',
			children: <UpdateUsers />,
		},
	];
	return (
		<>
			<Tabs
				destroyInactiveTabPane={true}
				centered
				items={pages}
				defaultActiveKey='2'
			/>
		</>
	);
}
