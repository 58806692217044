// Library Imports
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useCookies } from 'react-cookie';
// Custom Components and Functions
import './App.css';
import { authenticateUser } from './redux';
import { App } from './App';
var empty = require('is-empty');
// Types

function AppContainer({ history }) {
	const token = JSON.parse(localStorage.getItem('npdc'));
	// const isLoggedIn = useSelector(
	// 	(state) => state.auth.isLoggedIn
	// );

	const dispatch = useDispatch();
	useEffect(() => {
		if (!empty(token)) {
			dispatch(authenticateUser(history, token));
		} else {
			console.log(history);
			history.push('/login');
		}
	}, [token, history]);

	return <App history={history} />;
}

export default AppContainer;
