import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from '../CustomComponents/DatePickerDay';
import {
	Button,
	Form,
	Input,
	Select,
	Typography,
	message,
} from 'antd';
import Api from '../../helpers/api';

var empty = require('is-empty');

export default function AddNewAppointment({
	patientDetails,
	onModalClose,
	formattedDoctorsState,
	defaultDoctor,
}) {
	const { Title } = Typography;
	const [patient_id, setPatient_Id] = useState(' ');

	useEffect(() => {
		if (patientDetails !== '') {
			setPatient_Id(patientDetails.patient_id);
		}
	}, [patientDetails]);

	const onFinishFailed = (errorInfo) => {
		if (errorInfo.errorFields.length > 1) {
			message.error(
				'There is an Error in more than one field'
			);
		} else {
			message.error(
				`${errorInfo.errorFields[0].name[0]} field has an Error`
			);
		}
	};
	const onFinish = (values) => {
		if (values['Start Date'] > values['End Date']) {
			message.warning(
				'Start Date cannot be later than End Date'
			);
		} else {
			const api = new Api();
			api
				.init()
				.post('/protected/appointments/addnewappointment', {
					patientId: patient_id,
					appointmentStartDate: values['Start Date'],
					appointmentEndDate: values['End Date'],
					patientFname: values['First Name'],
					patientLName: values['Last Name'],
					patientPhoneNumber: values['Phone Number'],
					userId: values['Doctor'],
				})
				.then((appointemntDetails) => {
					if (!empty(appointemntDetails)) {
						message.success(
							'Appointment was successfully created'
						);
						onModalClose();
					} else {
						message.error(
							'Appointment could not be created. Please try again later.'
						);
					}
				})
				.catch((err) => {
					console.log(err);
					message.error(
						'Appointment could not be created. Please try again later.'
					);
				});
		}
	};
	return (
		<Form
			layout='horizontal'
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14, offset: 1 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			initialValues={{
				'First Name': patientDetails.fName,
				'Last Name': patientDetails.lName,
				'Phone Number': patientDetails.phoneNumber,
				'Start Date': patientDetails.startDate,
				'End Date': patientDetails.endDate,
				Doctor: defaultDoctor,
			}}>
			<Title level={4}>Add Appointment</Title>
			<Form.Item
				name='First Name'
				label='First Name'
				rules={[
					{
						required: true,
						message: 'First Name cannot be Empty',
					},
				]}>
				<Input allowClear />
			</Form.Item>
			<Form.Item
				name='Last Name'
				label='Last Name'
				rules={[
					{
						required: true,
						message: 'Last Name cannot be Empty',
					},
				]}>
				<Input allowClear />
			</Form.Item>
			<Form.Item
				name='Phone Number'
				label='Phone No.'
				validateFirst={true}
				rules={[
					{
						required: true,
						message: 'Phone number cannot be Empty',
					},
					{
						pattern:
							/(?:\s+|)((0|(?:(\+|)91))(?:\s|-)*(?:(?:\d(?:\s|-)*\d{9})|(?:\d{2}(?:\s|-)*\d{8})|(?:\d{3}(?:\s|-)*\d{7}))|\d{10})(?:\s+|)/,
						message: 'Enter a Valid Phone Number ',
					},
				]}>
				<Input allowClear />
			</Form.Item>
			<Form.Item
				name='Start Date'
				label='Start Date'
				rules={[
					{
						required: true,
						message: 'Last Name cannot be Empty',
					},
				]}>
				<DatePicker showTime />
				{/* <TimePicker /> */}
			</Form.Item>
			<Form.Item
				name='End Date'
				label='End Date'
				rules={[
					{
						required: true,
						message: 'Last Name cannot be Empty',
					},
				]}>
				<DatePicker showTime />
				{/* <TimePicker /> */}
			</Form.Item>
			<Form.Item
				name='Doctor'
				label='Doctor'
				rules={[
					{
						required: true,
						message:
							'Selecting Doctor to appointment is required',
					},
				]}>
				<Select
					// defaultValue={defaultDoctor}
					options={formattedDoctorsState}></Select>
			</Form.Item>
			<Form.Item wrapperCol={{ offset: 3, span: 16 }}>
				<Button
					className='search-button'
					type='primary'
					htmlType='submit'
					// onClick={next}
				>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
}
