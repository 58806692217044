import React, { useEffect, useState } from 'react';
import {
	Card,
	Form,
	Input,
	Button,
	message,
	Empty,
	List,
	Typography,
} from 'antd';
import Api from '../../helpers/api';
import './patient.css';
import _ from 'lodash';

function PatientDataList({ data }) {
	if (data.length === 0) {
		return <Empty />;
	} else {
		return (
			<List
				style={{ width: 600, margin: '0 auto' }}
				header={
					<Typography.Title level={5}>
						Select Patient
					</Typography.Title>
				}
				bordered
				dataSource={data}
				renderItem={(item) => (
					<List.Item>
						<Typography.Text>
							{`${item.patientFirstName} ${item.patientLastName}`}
						</Typography.Text>
						<Button
							type='primary'
							ghost
							href={`/patient/${item._id}`}>
							<Typography.Text>SELECT</Typography.Text>
						</Button>
					</List.Item>
				)}
			/>
		);
	}
}

export default function SearchPatient({ history }) {
	const [patientData, setPatientData] = useState([]);
	const onFinishFailed = (errorInfo) => {
		if (errorInfo.errorFields.length > 1) {
			message.error(
				'There is an Error in more than one field'
			);
		} else {
			message.error(
				`${errorInfo.errorFields[0].errors[0]}`
			);
		}
	};
	const onFinish = (values) => {
		if (
			_.isEmpty(values['First Name']) &&
			_.isEmpty(values['Last Name']) &&
			_.isEmpty(values['Phone Number'])
		) {
			message.error(
				'Atleast one of First name, Last Name, Phone number must be filled'
			);
		} else {
			const api = new Api();
			api
				.init()
				.get('/protected/patients/searchpatient', {
					params: {
						firstName: values['First Name'],
						lastName: values['Last Name'],
						phoneNumber: values['Phone Number'],
					},
				})
				.then((patientsResponse) => {
					if (patientsResponse.data.length > 0) {
						// const today = new Date();
						// today.setHours(today.getHours() + 1);
						setPatientData(patientsResponse.data);
					} else {
						message.info('Your search gave out 0 results');
					}
				})
				.catch((err) => {
					message.error(
						'There was an error loading patients. Please try again'
					);
				});
		}
	};
	return (
		<>
			<Card
				title='Search Patient'
				hoverable
				style={{ width: 600, margin: '0 auto' }}>
				<Form
					initialValues={{
						'First Name': '',
						'Last Name': '',
						'Phone Number': '',
					}}
					labelCol={{ span: 5 }}
					wrapperCol={{ span: 14, offset: 1 }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name='First Name'
						label='First Name'
						// rules={[
						// 	{
						// 		required: true,
						// 		message: 'First Name cannot be Empty',
						// 	},
						// ]}
					>
						<Input allowClear />
					</Form.Item>
					<Form.Item
						name='Last Name'
						label='Last Name'
						rules={[]}>
						<Input allowClear />
					</Form.Item>
					<Form.Item
						name='Phone Number'
						label='Phone Number'
						validateFirst={true}
						rules={[
							{
								pattern:
									/(?:\s+|)((0|(?:(\+|)91))(?:\s|-)*(?:(?:\d(?:\s|-)*\d{9})|(?:\d{2}(?:\s|-)*\d{8})|(?:\d{3}(?:\s|-)*\d{7}))|\d{10})(?:\s+|)/,
								message: 'Enter a Valid Phone Number ',
							},
						]}>
						<Input allowClear />
					</Form.Item>
					<Form.Item wrapperCol={{ offset: 10, span: 16 }}>
						<Button
							className='search-button'
							type='primary'
							htmlType='submit'>
							Search
						</Button>
					</Form.Item>
				</Form>
			</Card>
			<PatientDataList data={patientData} />
		</>
	);
}
