import React from 'react';
import './login.css';

import { Layout } from 'antd';
import Login from './Login';

const { Header, Content, Footer } = Layout;

export default function LoginLanding({ history }) {
	
	return (
		<Layout
			className='backgroundImage'
			style={{ height: '100vh' }}>
			<Header className='login-header'>
				{/* <img className="logo" src={logo} alt="Logo" /> */}
				Login Logo
			</Header>

			<Content
				style={{
					marginLeft: '40vmax',
					marginTop: '20vmin',
				}}>
				<Login history={history} />
			</Content>

			<Footer className='login-footer'>
				&#169;Navdurga Pediatric Dental Center
			</Footer>
		</Layout>
	);
}
