import React, { useState } from 'react';
import {
	Modal,
	Tabs,
	Popconfirm,
	Button,
	message,
} from 'antd';
import PatientDiagnosis from './PatientDiagnosis';
import PatientTreatment from './PatientTreatment';
import Api from '../../helpers/api';

export default function PatientDiagnosisTreatmentModal({
	loadAppointmnetInfo,
	modalVisibility,
	openAppointment,
	setOpenAppointment,
	setModalVisibility,
}) {
	// const [popConfirmVisibility, setpopConfirmVisibility] =
	// 	useState(false);
	console.log(openAppointment);
	const [currentTab, setCurrentTab] = useState('1');
	const { TabPane } = Tabs;
	const onModalClose = () => {
		loadAppointmnetInfo();
		setOpenAppointment({});
		// setModalVisibility(!visibility);
		setModalVisibility(false);
	};
	const defaultPermanentTeeth = {
		11: false,
		12: false,
		13: false,
		14: false,
		15: false,
		16: false,
		17: false,
		18: false,
		21: false,
		22: false,
		23: false,
		24: false,
		25: false,
		26: false,
		27: false,
		28: false,
		31: false,
		32: false,
		33: false,
		34: false,
		35: false,
		36: false,
		37: false,
		38: false,
		41: false,
		42: false,
		43: false,
		44: false,
		45: false,
		46: false,
		47: false,
		48: false,
	};
	const defaultMilkTeeth = {
		55: false,
		54: false,
		53: false,
		52: false,
		51: false,
		61: false,
		62: false,
		63: false,
		64: false,
		65: false,
		85: false,
		84: false,
		83: false,
		82: false,
		81: false,
		71: false,
		72: false,
		73: false,
		74: false,
		75: false,
	};
	return (
		<Modal
			width={1500}
			destroyOnClose
			visible={modalVisibility}
			onCancel={onModalClose}
			footer={[
				<Button type='secondary' onClick={onModalClose}>
					Cancel
				</Button>,
				<Popconfirm
					title='Are you sure you want to delete this appointment permanantly ?'
					onConfirm={() => {
						if (openAppointment._id) {
							const api = new Api();
							api
								.init()
								.delete(
									'/protected/appointments/deleteappointment',
									{
										params: {
											appointmentId: openAppointment._id,
										},
									}
								)
								.then((responseData) => {
									if (responseData.status === 200) {
										message.success(
											'Appointment deleted successfully'
										);
										onModalClose();
									} else {
										message.error(
											'Unable to delete Appointment'
										);
										onModalClose();
									}
								})
								.catch((err) => {
									if (err.response.data.errList.message) {
										console.log(
											err.response.data.errList.message
										);
										message.error(
											err.response.data.errList.message
										);
										onModalClose();
									} else {
										message.error(
											'Unable to delete Appointment'
										);
										onModalClose();
									}
								});
						}
					}}
					okText='Yes'
					cancelText='No'>
					<Button danger type='secondary'>
						Delete
					</Button>
				</Popconfirm>,
			]}>
			<Tabs
				onChange={(key) => {
					setCurrentTab(key);
				}}
				type='card'>
				<TabPane tab='Diagnosis Tab' key='1'>
					<PatientDiagnosis
						openAppointment={openAppointment}
						defaultPermanentTeeth={defaultPermanentTeeth}
						defaultMilkTeeth={defaultMilkTeeth}
					/>
				</TabPane>
				<TabPane tab='Treatment Tab' key='2'>
					<PatientTreatment
						openAppointment={openAppointment}
						defaultPermanentTeeth={defaultPermanentTeeth}
						defaultMilkTeeth={defaultMilkTeeth}
					/>
				</TabPane>
			</Tabs>
		</Modal>
	);
}
