import React, { useState } from 'react';
import {
	Tabs,
	Input,
	Form,
	Button,
	message,
	Typography,
	Tag,
	Popconfirm,
} from 'antd';
import { Paper, Grid, makeStyles } from '@material-ui/core';
import Api from '../../helpers/api';
import PermanentTeeth from './PermanentTeeth';
import MilkTeeth from './MilkTeeth';

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

export default function PatientTreatment({
	openAppointment,
	defaultPermanentTeeth,
	defaultMilkTeeth,
}) {
	const { TabPane } = Tabs;
	const { Title } = Typography;
	const [currentTab, setCurrentTab] = useState('1');
	const [milkTeeth, setMilkTeeth] = useState(
		defaultMilkTeeth
	);
	const [permanentTeeth, setPermanentTeeth] = useState(
		defaultPermanentTeeth
	);
	const [treatment, setTreatment] = useState(
		openAppointment.appointmentTreatmentDone
	);
	const [form] = Form.useForm();

	const onFinish = (values) => {
		let finalTreatmentString = `${values['Treatment Text']}:- `;
		let permanentTeethString = '';
		let milkTeethString = '';
		for (const [key, value] of Object.entries(
			permanentTeeth
		)) {
			if (value) {
				if (permanentTeethString === '') {
					permanentTeethString += String(key);
				} else {
					permanentTeethString += ',' + String(key);
				}
			}
		}
		for (const [key, value] of Object.entries(milkTeeth)) {
			if (value) {
				if (milkTeethString === '') {
					milkTeethString += String(key);
				} else {
					milkTeethString += ',' + String(key);
				}
			}
		}
		if (permanentTeethString !== '') {
			finalTreatmentString += permanentTeethString;
		}
		if (milkTeethString !== '') {
			if (permanentTeethString !== '') {
				finalTreatmentString += ',';
			}
			finalTreatmentString += milkTeethString;
		}
		if (
			milkTeethString === '' &&
			permanentTeethString === ''
		) {
			finalTreatmentString = finalTreatmentString.replace(
				':- ',
				' '
			);
		}
		setTreatment([...treatment, finalTreatmentString]);
		form.resetFields();
		setPermanentTeeth(defaultPermanentTeeth);
		setMilkTeeth(defaultMilkTeeth);
	};

	const onFinishFailed = (errorInfo) => {
		message.error(errorInfo.errorFields[0].errors[0]);
	};
	const confirmTreatment = () => {
		const api = new Api();
		api
			.init()
			.patch(
				'/protected/appointments/updateappointmenttreatment',
				{
					appointmentId: openAppointment._id,
					treatment: treatment,
				}
			)
			.then((responseData) => {
				if (responseData && responseData.status === 200) {
					message.success('Treatment Updated Successfully');
				} else {
					message.error('Error Updating Treatment');
				}
			});
	};
	const classes = useStyles();
	return (
		<Grid>
			<Title level={2}>Patient Appointment Treatment</Title>
			<Paper elevation={3} className={classes.paper}>
				{treatment.length === 0 ? (
					<Typography>
						No Treatment Added to Appointment
					</Typography>
				) : (
					treatment.map((element) => (
						<Tag
							color='volcano'
							closable
							onClose={(e) => {
								setTreatment(
									treatment.filter(function (item) {
										return item !== element;
									})
								);
							}}>
							{element}
						</Tag>
					))
				)}
				<Popconfirm
					title='Are you sure you want to save Treatment'
					onConfirm={confirmTreatment}>
					<Button type='primary'>Save</Button>
				</Popconfirm>
			</Paper>
			<Tabs
				tabPosition='left'
				onChange={(key) => {
					setCurrentTab(key);
				}}>
				<TabPane tab='Permanent Teeth' key='1'>
					<PermanentTeeth
						permanentTeeth={permanentTeeth}
						setPermanentTeeth={setPermanentTeeth}
					/>
				</TabPane>
				<TabPane tab='Milk Teeth' key='2'>
					<MilkTeeth
						milkTeeth={milkTeeth}
						setMilkTeeth={setMilkTeeth}
					/>
				</TabPane>
			</Tabs>
			<Grid style={{ marginTop: '1rem' }}>
				<Form
					form={form}
					layout='inline'
					initialValues={{ 'Treatment Text': '' }}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 14 }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name='Treatment Text'
						label='Treatment Name'
						rules={[
							{
								required: true,
								message: 'Treatment Text cannot be Empty',
							},
						]}>
						<Input
							placeholder='Treatment Text'
							style={{ width: '20rem' }}
						/>
					</Form.Item>
					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Add
						</Button>
					</Form.Item>
				</Form>
			</Grid>
		</Grid>
	);
}
