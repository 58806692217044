import { combineReducers } from 'redux';
import authReducer from './Authentication/authReducer';
import authErrorReducer from './Authentication/authErrorReducers';
import appointmentReducer from './Appointments/appointmentReducers';

const rootReducer = combineReducers({
	auth: authReducer,
	authError: authErrorReducer,
	appointments: appointmentReducer,
});

export default rootReducer;
