import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
	Form,
	Input,
	Select,
	Card,
	Button,
	Typography,
	message,
	Row,
	Col,
} from 'antd';
import ExistingUserList from './ExistingUserList';
import UpdateUsersFormModal from './UpdateUsersFormModal';
import Divider from '@mui/material/Divider';
import Api from '../../helpers/api';
import Spinner from '../Spinners/Spinner';

export default function UpdateUsers() {
	const [userList, setUserList] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [selectedId, setSelectedId] = useState('');
	const [openModal, setModalOpen] = useState(false);
	function getAllUsers() {
		const api = new Api();
		api
			.init()
			.get('/protected/users')
			.then((users) => {
				if (_.isEmpty(users)) {
					message.error(
						'There was an error loading all users. Please try again later.'
					);
				} else {
					message.success(`Successfully loaded all users`);
					setUserList(users.data);
				}
			});
	}

	useEffect(() => {
		getAllUsers();
	}, []);
	return (
		<>
			<Card
				title={
					<h2>
						<b>Update Users</b>
					</h2>
				}>
				<Row>
					<Col span={22}>
						{userList.length < 1 ? (
							<Spinner />
						) : (
							<ExistingUserList
								userList={userList}
								selectedItem={selectedItem}
								setSelectedItem={setSelectedItem}
								setModalOpen={setModalOpen}
								setSelectedId={setSelectedId}
							/>
						)}
						<UpdateUsersFormModal
							selectedItem={selectedItem}
							openModal={openModal}
							setModalOpen={setModalOpen}
							setSelectedItem={setSelectedItem}
							selectedId={selectedId}
						/>
					</Col>
					{/* <Col span={2}>
						<Divider orientation='vertical' />
					</Col>
					
						
					</Col> */}
				</Row>
			</Card>
		</>
	);
}
