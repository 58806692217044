import {
  GET_ERRORS,

} from "./authTypes";




const initialState = {
  authErrorMessage: ''
}

export default function authErrorReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_ERRORS:
      return {
        ...state,
        authErrorMessage: payload
      }
    default:
      return state
  }

}