// Library Imports
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
	message,
	Form,
	Input,
	Button,
	Card,
	Space,
	AutoComplete,
} from 'antd';
import {
	UserOutlined,
	LockOutlined,
} from '@ant-design/icons';
import {
	EyeInvisibleOutlined,
	EyeTwoTone,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

// Custom Components and Functions
import {
	loginAction,
	setLoginErrorsAction,
} from '../../redux';
import { validateLogin } from './LoginValidations';
// CSS
import './login.css';
import { authenticateUser } from '../../redux';
// Types

// import { errorMessage } from '../../redux/Authentication/authInterfaces'
import { RootState } from '../../redux/store';
var empty = require('is-empty');

const Login = ({ history }) => {
	const errorMessage = useSelector(
		(state) => state.authError.authErrorMessage
	);
	const dispatch = useDispatch();
	const token = JSON.parse(localStorage.getItem('npdc'));
	const [form] = Form.useForm();
	useEffect(() => {
		if (!empty(token)) {
			dispatch(authenticateUser(history, token));
			history.push('/home');
		}
	}, []);
	useEffect(() => {
		if (errorMessage !== '') {
			console.log(errorMessage);
			message.error(errorMessage);
			dispatch(setLoginErrorsAction(''));
		}
	}, [errorMessage]);
	const onFinishFailed = (errorInfo) => {
		if (errorInfo.errorFields.length > 1) {
			message.error(
				'There is an Error in more than one field'
			);
		} else {
			message.error(
				`${errorInfo.errorFields[0].errors[0]}`
			);
		}
	};
	const onFinish = (values) => {
		dispatch(
			loginAction(
				{ email: values.email, password: values.password },
				history
			)
		);
	};

	return (
		<Space direction='vertical'>
			<Card className='login-card' title='Sign In'>
				<Row className='login-form'>
					<Form
						// form={form}
						initialValues={{
							email: '',
							password: '',
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						labelAlign='left'>
						<Col>
							<Form.Item
								className='input'
								name='email'
								rules={[
									{
										required: true,
										message: 'Email cannot be empty',
									},
									{
										pattern:
											/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
										message: 'Enter a valid Email',
									},
								]}>
								<Input
									prefix={
										<UserOutlined className='site-form-item-icon' />
									}
									allowClear
									id='email'
									placeholder='Email'
								/>
							</Form.Item>
						</Col>
						<Col>
							<Form.Item
								className='input'
								name='password'
								rules={[
									{
										required: true,
										message: 'Password cannot be empty',
									},
								]}>
								<Input.Password
									placeholder='Password'
									id='password'
									prefix={
										<LockOutlined className='site-form-item-icon' />
									}
									iconRender={(visible) =>
										visible ? (
											<EyeTwoTone />
										) : (
											<EyeInvisibleOutlined />
										)
									}
								/>
							</Form.Item>
						</Col>
						<Form.Item>
							<Button
								type='primary'
								htmlType='submit'
								className='login-form-button'>
								Log In
							</Button>
						</Form.Item>
					</Form>
				</Row>
			</Card>
		</Space>
	);
};
export default Login;
