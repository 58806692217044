import React, { useEffect, useState } from 'react';
import './calander.css';
import {
	Button,
	Form,
	Input,
	Typography,
	message,
} from 'antd';
import Api from '../../helpers/api';
const empty = require('is-empty');

export default function PatientSearchForm({
	setSearchedPatients,
	patientSearchFormValues,
	setPatientSearchFormValues,
	setPatientDetails,
	next,
	patientDetails,
}) {
	const { Title } = Typography;
	const onFinishFailed = (errorInfo) => {
		if (errorInfo.errorFields.length > 1) {
			message.error(
				'There is an Error in more than one field'
			);
		} else {
			message.error(
				`${errorInfo.errorFields[0].name[0]} field has an Error`
			);
		}
	};
	const onFinish = (values) => {
		setPatientSearchFormValues({
			FName: values['First Name'],
			LName: values['Last Name'],
			PNumber: values['Phone Number'],
		});
		const api = new Api();
		api
			.init()
			.get('/protected/patients/searchpatient', {
				params: {
					firstName: values['First Name'],
					lastName: values['Last Name'],
					phoneNumber: values['Phone Number'],
				},
			})
			.then((patientsResponse) => {
				if (patientsResponse.data.length === 0) {
					// const today = new Date();
					// today.setHours(today.getHours() + 1);
					setPatientDetails({
						...patientDetails,
						fName: values['First Name'],
						lName: values['Last Name'],
						phoneNumber: values['Phone Number'],
					});
					next(2);
				} else {
					setSearchedPatients(patientsResponse.data);
					next(1);
				}
			})
			.catch((err) => {
				console.log(err);
				message.error(
					'There was an error loading patients. Please try again'
				);
			});
	};
	return (
		<Form
			initialValues={{
				'First Name': patientSearchFormValues.FName,
				'Last Name': patientSearchFormValues.LName,
				'Phone Number': patientSearchFormValues.PNumber,
			}}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14, offset: 1 }}
			className='search-form'
			layout='horizontal'
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			// onValuesChange={(changedValues, allValues) => {
			// 	// console.log(changedValues);
			// 	// console.log(allValues);
			// }}
		>
			<Title level={4}>Search Patient</Title>
			<Form.Item
				name='First Name'
				label='First Name'
				rules={[
					{
						required: true,
						message: 'First Name cannot be Empty',
					},
				]}>
				<Input allowClear />
			</Form.Item>
			<Form.Item
				name='Last Name'
				label='Last Name'
				rules={[]}>
				<Input allowClear />
			</Form.Item>
			<Form.Item
				name='Phone Number'
				label='Phone Number'
				validateFirst={true}
				rules={[
					{
						pattern:
							/(?:\s+|)((0|(?:(\+|)91))(?:\s|-)*(?:(?:\d(?:\s|-)*\d{9})|(?:\d{2}(?:\s|-)*\d{8})|(?:\d{3}(?:\s|-)*\d{7}))|\d{10})(?:\s+|)/,
						message: 'Enter a Valid Phone Number ',
					},
				]}>
				<Input allowClear />
			</Form.Item>
			<Form.Item wrapperCol={{ offset: 3, span: 16 }}>
				<Button
					className='search-button'
					type='primary'
					htmlType='submit'>
					Search
				</Button>
			</Form.Item>
		</Form>
	);
}
