import React, { useEffect, useState } from 'react';
import {
	message,
	Empty,
	Typography,
	Form,
	Input,
	Collapse,
	Select,
	AutoComplete,
	Checkbox,
	Button,
} from 'antd';
import PatientAppointmentHistory from './PatientAppointmentHistory';
import DatePicker from '../CustomComponents/DatePickerDay';
import { PlusOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from '../../helpers/api';
import Item from 'antd/lib/list/Item';
import './patient.css';
import { convertDateForAntD } from '../../helpers/helpers';
var empty = require('is-empty');

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		width: 800,
		margin: '0 auto',
		marginTop: '1em',
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	timelinePaper: {
		margin: '0 auto',
		padding: theme.spacing(1),
		textAlign: 'center',
	},
}));
export default function PatientInfo({ history, match }) {
	const [patientData, setPatientData] = useState({});
	const [disabledSave, setDisabledSave] = useState(true);
	// const [patientHistory, setPatientHistory] = useState({});
	const [options, setOptions] = useState([]);

	const classes = useStyles();
	const { Panel } = Collapse;
	const { Option } = Select;
	const { patient_id } = match.params;
	useEffect(() => {
		const api = new Api();
		api
			.init()
			.get('/protected/patients/patientinfo', {
				params: {
					patientId: patient_id,
				},
			})
			.then((patientDetails) => {
				if (patientDetails.data.patientDOB) {
					console.log(patientDetails);
					patientDetails.data.patientDOB =
						convertDateForAntD(
							patientDetails.data.patientDOB
						);
				}

				setPatientData(patientDetails.data);
			})
			.catch((err) => {
				message.error('Could not find Patient');
				history.push('/patient');
			});
	}, [patient_id]);
	useEffect(() => {
		const api = new Api();
		api
			.init()
			.get('/protected/patients/uniquereferrences')
			.then((uniqueReferrences) => {
				setOptions(
					uniqueReferrences.data.map((reference) => {
						return { value: reference };
					})
				);
			})
			.catch((err) => {
				message.error('Could not load references');
			});
	}, []);
	const onDeletePatient = () => {
		const patientId = patientData._id;

		if (!patientId) {
			message.error('Could not find patient');
		}
		const api = new Api();
		api
			.init()
			.delete('/protected/patients/deletepatient', {
				params: { patientId: patientId },
			})
			.then((res) => {
				message.success('Patient Successfully Deleted');
				history.push('/patient');
			})
			.catch((err) => {
				message.error('Could not Delete patients');
			});
	};
	const onValuesChange = (changedValues, allValues) => {
		if (!empty(changedValues)) {
			setDisabledSave(false);
		}
	};
	const onFinish = (values) => {
		const api = new Api();
		api
			.init()
			.patch('/protected/patients/updatepatientinfo', {
				patientId: patient_id,
				patientDetails: { ...values },
			})
			.then((responseData) => {
				if (responseData && responseData.status === 200) {
					message.success(
						'Patient Data saved Successfully'
					);
				} else {
					message.error('Error Saving Patient Data');
				}
			})
			.catch((err) => {
				message.error('Error Saving Patient Data');
			});
	};

	if (!empty(patientData)) {
		return (
			<>
				<Paper className={classes.paper}>
					<Grid
						container
						direction='column'
						justify='center'
						alignItems='center'>
						<Typography.Title level={2}>
							{' '}
							Patient Form
						</Typography.Title>
						<Form
							initialValues={{
								...patientData,
							}}
							onValuesChange={onValuesChange}
							onFinish={onFinish}
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 10, offset: 1 }}>
							<Grid item container direction='row'>
								<Grid item>
									<Form.Item
										name='patientFirstName'
										label='First Name'
										rules={[
											{
												required: true,
												message:
													'First Name cannot be Empty',
											},
										]}>
										<Input allowClear />
									</Form.Item>

									<Form.Item
										name='phoneNumber'
										label='Phone Number'
										rules={[
											{
												required: true,
												message:
													'Phone number cannot be Empty',
											},
											{
												pattern:
													/(?:\s+|)((0|(?:(\+|)91))(?:\s|-)*(?:(?:\d(?:\s|-)*\d{9})|(?:\d{2}(?:\s|-)*\d{8})|(?:\d{3}(?:\s|-)*\d{7}))|\d{10})(?:\s+|)/,
												message:
													'Enter a Valid Phone Number ',
											},
										]}>
										<Input allowClear />
									</Form.Item>
								</Grid>
								<Grid item>
									<Form.Item
										name='patientLastName'
										label='Last Name'
										rules={[
											{
												required: true,
												message:
													'Last Name cannot be Empty',
											},
										]}>
										<Input allowClear />
									</Form.Item>

									<Form.Item
										name='patientEmail'
										label='Patient Email'
										wrapperCol={{ span: 16 }}
										rules={[
											{
												pattern:
													/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
												message: 'Enter a valid Email',
											},
										]}>
										<Input allowClear />
									</Form.Item>
								</Grid>
							</Grid>
							<Collapse
								expandIcon={() => <PlusOutlined />}
								ghost
								bordered={false}>
								<Panel header='Patient General Information'>
									<Grid item container direction='row'>
										<Grid item>
											<Form.Item
												name='patientAge'
												label='Age'
												rules={[
													{
														pattern: /^[0-9]+$/,
														message:
															'Age should be a number',
													},
												]}>
												<Input allowClear />
											</Form.Item>
											<Form.Item
												labelCol={{ span: 10 }}
												wrapperCol={{ span: 12 }}
												name='patientDOB'
												label='Date of Birth'>
												<DatePicker />
											</Form.Item>
										</Grid>
										<Grid item>
											<Form.Item
												name='patientGender'
												label='Gender'>
												<Select
													style={{ width: 120 }}
													// onChange={handleChange}
												>
													<Option value='Unk'>Unk</Option>
													<Option value='M'>M</Option>
													<Option value='F'>F</Option>
												</Select>
											</Form.Item>

											<Form.Item
												labelCol={{ span: 10 }}
												name='patientReferredBy'
												label='Reffered By'>
												<AutoComplete
													options={options}
													filterOption={(
														inputValue,
														option
													) =>
														option.value
															.toUpperCase()
															.indexOf(
																inputValue.toUpperCase()
															) !== -1
													}
												/>
											</Form.Item>
										</Grid>
									</Grid>
								</Panel>
							</Collapse>
							<Collapse
								expandIcon={() => <PlusOutlined />}
								ghost
								bordered={false}>
								<Panel header='Patient Medical Information'>
									<Grid
										item
										container
										direction='column'
										justify='center'
										alignItems='center'>
										<Grid item>
											<Form.Item
												labelCol={{ span: 10 }}
												name='patientBloodGroup'
												label='Blood Group'>
												<Select style={{ width: 120 }}>
													<Option value='O+'>O+</Option>
													<Option value='A+'>A+</Option>
													<Option value='B+'>B+</Option>
													<Option value='AB+'>AB+</Option>
													<Option value='O-'>O-</Option>
													<Option value='A-'>A-</Option>
													<Option value='B-'>B-</Option>
													<Option value='AB-'>AB-</Option>
												</Select>
											</Form.Item>
										</Grid>
										<Grid
											item
											container
											direction='row'
											justify='center'
											alignItems='center'>
											<Grid item>
												<Form.Item
													labelCol={{ span: 5 }}
													wrapperCol={{ span: 10 }}
													name='aids'
													valuePropName='checked'>
													<Checkbox>Aids</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 4,
													}}
													name='asthama'
													valuePropName='checked'>
													<Checkbox>Asthama</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 6,
													}}
													name='diabetes'
													valuePropName='checked'>
													<Checkbox>Diabetes</Checkbox>
												</Form.Item>
											</Grid>
										</Grid>
										<Grid
											item
											container
											direction='row'
											justify='center'
											alignItems='center'>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{ span: 10 }}
													name='heartProblems'
													valuePropName='checked'>
													<Checkbox>
														Heart Problems
													</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 4,
													}}
													name='fitsFaints'
													valuePropName='checked'>
													<Checkbox>Fits & Faints</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 6,
													}}
													name='drugAllergies'
													valuePropName='checked'>
													<Checkbox>
														Drug Allergies
													</Checkbox>
												</Form.Item>
											</Grid>
										</Grid>
										<Grid
											item
											container
											direction='row'
											justify='center'
											alignItems='center'>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{ span: 10 }}
													name='hepatitisLiver'
													valuePropName='checked'>
													<Checkbox>
														Hepatitis/Liver
													</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 4,
													}}
													name='infectiousDiseases'
													valuePropName='checked'>
													<Checkbox>
														Infectious Diseases
													</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 6,
													}}
													name='abnormalBleeding'
													valuePropName='checked'>
													<Checkbox>
														Abnormal Bleeding
													</Checkbox>
												</Form.Item>
											</Grid>
										</Grid>
										<Grid
											item
											container
											direction='row'
											justify='center'
											alignItems='center'>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{ span: 10 }}
													name='pregnancy'
													valuePropName='checked'>
													<Checkbox>Pregnancy</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 4,
													}}
													name='hypertension'
													valuePropName='checked'>
													<Checkbox>Hypertension</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 6,
													}}
													name='kidneyDisease'
													valuePropName='checked'>
													<Checkbox>
														Kidney Disease
													</Checkbox>
												</Form.Item>
											</Grid>
										</Grid>
										<Grid
											item
											container
											direction='row'
											justify='center'
											alignItems='center'>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{ span: 10 }}
													name='thyroid'
													valuePropName='checked'>
													<Checkbox>Thyroid</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 4,
													}}
													name='anemia'
													valuePropName='checked'>
													<Checkbox>Anemia</Checkbox>
												</Form.Item>
											</Grid>
											<Grid item>
												<Form.Item
													labelCol={{ span: 10 }}
													wrapperCol={{
														span: 10,
														offset: 6,
													}}
													name='majorSurgery'
													valuePropName='checked'>
													<Checkbox>Major Surgery</Checkbox>
												</Form.Item>
											</Grid>
										</Grid>
										<Grid>
											<Form.Item name='other' label='Other'>
												<Input allowClear />
											</Form.Item>
										</Grid>
									</Grid>
								</Panel>
							</Collapse>
							<Form.Item
								wrapperCol={{ offset: 10, span: 16 }}>
								<Button
									style={{ marginRight: '1rem' }}
									disabled={disabledSave}
									type='primary'
									htmlType='submit'>
									Save
								</Button>
								<Button
									type='primary'
									danger
									htmlType='button'
									onClick={onDeletePatient}>
									Delete
								</Button>
							</Form.Item>
						</Form>
					</Grid>
				</Paper>
				<Paper className={classes.paper}>
					<Typography.Title level={4}>
						{' '}
						Patient Appointment history
					</Typography.Title>
					<Scrollbars style={{ height: 200 }}>
						<PatientAppointmentHistory
							patient_id={patient_id}
						/>
					</Scrollbars>
				</Paper>
			</>
		);
	} else {
		return (
			<Empty
				description={
					<Typography>Patient not Found</Typography>
				}
			/>
		);
	}
}
