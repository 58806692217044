import {
	GET_ERRORS,
	LOGIN_AND_SET_CURRENT_USER,
	AUTHENTICATE_USER,
	LOGOUT_USER,
} from './authTypes';
import Api from '../../helpers/api';
import { useCookies } from 'react-cookie';

export const setLoginErrorsAction = (error) => {
	return { type: GET_ERRORS, payload: error };
};
export const authenticateUser = (history, token) => {
	return async function authenticate(dispatch) {
		const apiClient = new Api();
		apiClient
			.init(token)
			.get('/protected/authenticate')
			.then((response) => {
				dispatch({
					type: AUTHENTICATE_USER,
					payload: {
						authenticated: response.data.success,
						user: response.data.user,
						token: token,
					},
				});
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response);
				if (error.response & error.response.data) {
					// dispatch(
					// 	setLoginErrorsAction(error.response.data)
					// );
					history.push('/login');
				}
			});
	};
};
export const logoutAction = (history) => {
	return async function logout(dispatch) {
		dispatch({ type: LOGOUT_USER, payload: null });
		history.push('/login');
	};
};
// REACT_APP_API_ENDPOINT=http://localhost:3001/api
export const loginAction = (userInputValues, history) => {
	return async function login(dispatch, getState) {
		const apiClient = new Api();
		apiClient
			.init()
			.post('/authroute/login', {
				email: userInputValues.email,
				password: userInputValues.password,
			})
			.then((response) => {
				dispatch({
					type: LOGIN_AND_SET_CURRENT_USER,
					payload: response.data,
				});

				history.push('/home');
			})
			.catch((error) => {
				// Send message as a alert

				console.log(error);
				if (
					error.response &
					error.response.data &
					error.response.data.errList.message
				) {
					dispatch(
						setLoginErrorsAction(
							error.response.data.errList.message
						)
					);
				}
			});
	};
};
