import {
	LOGIN_AND_SET_CURRENT_USER,
	AUTHENTICATE_USER,
	LOGOUT_USER,
} from './authTypes';
import { useCookies } from 'react-cookie';

const initialState = {
	user: null,
	token: null,
	authenticated: false,
};

export default function authReducer(
	state = initialState,
	action
) {
	const { type, payload } = action;
	switch (type) {
		case LOGIN_AND_SET_CURRENT_USER:
			// const [cookies, setCookie] = useCookies(['ndc']);
			localStorage.setItem(
				'npdc',
				JSON.stringify(payload.token)
			);
			return {
				...state,

				user: payload.user,
				token: payload.token,
			};
		case LOGOUT_USER:
			localStorage.removeItem('npdc');
			return {
				...state,
				user: null,
				token: null,
				authenticated: false,
			};
		case AUTHENTICATE_USER:
			return {
				...state,
				authenticated: payload.authenticated,
				user: payload.user,
				token: payload.token,
			};
		// case GET_ERRORS:
		//   return {
		//     ...state,
		//     authErrorMessage: payload
		//   }

		default:
			return state;
	}
}
