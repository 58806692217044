import axios from 'axios';

// import { apiTypes, header } from './apiTypes';

export default class Api {
	// api_token: apiTypes['api_token']
	// client: apiTypes['client']
	// api_url: apiTypes['api_url']
	constructor() {
		this.api_token = null;
		this.client = null;
		this.api_url =
			process.env.REACT_APP_API_ENDPOINT + '/webapp';
		// React url doesn't have a backslash
	}

	init = () => {
		this.api_token = JSON.parse(
			localStorage.getItem('npdc')
		);

		let headers /* : header */ = {
			Accept: 'application/json',
		};

		if (this.api_token) {
			headers.Authorization = this.api_token; //`Bearer ${this.api_token}`;
			this.client = axios.create({
				baseURL: this.api_url,
				timeout: 31000,
				headers: headers,
			});
		} else {
			this.client = axios.create({
				baseURL: this.api_url,
				timeout: 31000,
			});
		}

		return this.client;
	};
}
