import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
	Form,
	Input,
	Select,
	Card,
	Button,
	Typography,
	message,
} from 'antd';
import { SliderPicker } from 'react-color';
import { ADMIN, STAFF, DOCTOR } from '../Constants';
import Api from '../../helpers/api';

const { Option } = Select;
const { Title } = Typography;
const tailLayout = {
	wrapperCol: { offset: 8, span: 16 },
};

export default function AddNewUser() {
	const [color, setColor] = useState('#000');
	const [form] = Form.useForm();

	function validationErrors(values) {
		if (_.isEmpty(values['firstName'])) {
			message.error('First name Cannot be empty');
		}
		if (_.isEmpty(values['lastName'])) {
			message.error('Last name Cannot be empty');
		}
		if (_.isEmpty(values['phoneNumber'])) {
			message.error('Phone Number Cannot be empty');
		}
		if (_.isEmpty(values['email'])) {
			message.error('Email Cannot be empty');
		}
		if (_.isEmpty(values['password'])) {
			message.error('Password Cannot be empty');
		}
		if (_.isEmpty(values['roles'])) {
			message.error('Roles Cannot be empty');
		}
		if (_.isEmpty(values['color'])) {
			message.error('Color Cannot be empty');
		}
	}

	function onSubmit(values) {
		if (
			!_.isEmpty(values['firstName']) &&
			!_.isEmpty(values['lastName']) &&
			!_.isEmpty(values['phoneNumber']) &&
			!_.isEmpty(values['email']) &&
			!_.isEmpty(values['password']) &&
			!_.isEmpty(values['roles']) &&
			!_.isEmpty(values['color'])
		) {
			const roles = values['roles'];
			const colors = values['color'].hex;
			const api = new Api();
			api
				.init()
				.post('/authroute/register', {
					firstName: values['firstName'],
					lastName: values['lastName'],
					phoneNumber: values['phoneNumber'],
					email: values['email'],
					password: values['password'],
					roles: roles,
					permission: ['All'],
					assignedColor: colors,
				})
				.then((userDetails) => {
					if (_.isEmpty(userDetails)) {
						message.error(
							'There was an error creating user. Please try again later.'
						);
					} else {
						message.success(
							`Successfully created user ${userDetails.firstName}`
						);
						form.resetFields();
					}
				})
				.catch((err) => {
					console.log(err);
					message.error(
						'There was an error creating user. Please try again later.'
					);
				});
		} else {
			validationErrors(values);
		}
	}
	function getRoleOptions() {
		const rolesAvailable = [ADMIN, STAFF, DOCTOR];
		return rolesAvailable.map((role) => {
			return { label: role, value: role };
		});
	}

	return (
		<>
			<Card
				title={
					<h2>
						<b>Add Users: </b>
					</h2>
				}>
				<Form
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 8 }}
					form={form}
					onFinish={onSubmit}>
					<Form.Item
						label='First Name'
						name='firstName'
						required>
						<Input />
					</Form.Item>
					<Form.Item
						label='Last Name'
						name='lastName'
						required>
						<Input />
					</Form.Item>
					<Form.Item
						label='Phone Number'
						name='phoneNumber'
						required>
						<Input />
					</Form.Item>
					<Form.Item label='E-mail' name='email' required>
						<Input />
					</Form.Item>
					<Form.Item
						label='Password'
						name='password'
						required>
						<Input />
					</Form.Item>
					<Form.Item label='Roles' name='roles' required>
						<Select
							mode='multiple'
							allowClear
							options={getRoleOptions()}
						/>
					</Form.Item>
					<Form.Item label='Color' name='color' required>
						<SliderPicker
							color={color}
							onChangeComplete={(color) => {
								console.log(color.hex);
								setColor(color.hex);
							}}
						/>
					</Form.Item>
					<Form.Item
						label='Selected Color'
						name='selectedColor'>
						<div
							style={{
								width: '20px',
								height: '20px',
								backgroundColor: color,
							}}
						/>
					</Form.Item>
					<Form.Item {...tailLayout}>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</>
	);
}
