import React, { useEffect, useState } from 'react';
import { Steps, message, Typography, Button } from 'antd';
import AddNewAppointment from './AddNewAppointment';

import PatientSearchForm from './PatientSearchForm';
import PatientList from './PatientList';
import Api from '../../helpers/api';
import dayjs from 'dayjs';
import {
	formatDoctorsObject,
	getDefaultDoctor,
} from './utils';
import Spinner from '../Spinners/Spinner';
var empty = require('is-empty');

const GetSteps = ({
	currentStep,
	setSearchedPatients,
	searchedPatients,
	setPatientDetails,
	next,
	patientDetails,
	patientSearchFormValues,
	setPatientSearchFormValues,
	onModalClose,
	doctorsInfo,
}) => {
	const [formattedDoctorsState, setFormattedDoctorsState] =
		useState([]);
	const [defaultDoctor, setDefaultDoctorState] =
		useState('');
	useEffect(() => {
		const formattedDoctors =
			formatDoctorsObject(doctorsInfo);
		console.log(doctorsInfo);
		setFormattedDoctorsState(formattedDoctors);

		const defaultDoctor = getDefaultDoctor(
			formattedDoctors,
			'Akshata Shenvi'
		);
		setDefaultDoctorState(defaultDoctor);
	}, [doctorsInfo]);
	if (currentStep === 0) {
		return (
			<PatientSearchForm
				setSearchedPatients={setSearchedPatients}
				setPatientDetails={setPatientDetails}
				patientSearchFormValues={patientSearchFormValues}
				setPatientSearchFormValues={
					setPatientSearchFormValues
				}
				next={next}
				patientDetails={patientDetails}
			/>
		);
	} else if (currentStep === 1) {
		return (
			<PatientList
				patientDetails={patientDetails}
				searchedPatients={searchedPatients}
				setPatientDetails={setPatientDetails}
				next={next}
			/>
		);
	} else if (currentStep === 2) {
		return defaultDoctor === '' ? (
			<Spinner />
		) : (
			<AddNewAppointment
				patientDetails={patientDetails}
				onModalClose={onModalClose}
				formattedDoctorsState={formattedDoctorsState}
				defaultDoctor={defaultDoctor}
			/>
		);
	}
};

export default function NewAppointmentForm({
	appointmentInfo,
	onModalClose,
}) {
	const [
		patientSearchFormValues,
		setPatientSearchFormValues,
	] = useState({
		FName: '',
		LName: '',
		PNumber: '',
	});
	const [patientDetails, setPatientDetails] = useState({
		patient_id: '',

		fName: '',
		lName: '',
		phoneNumber: '',
		startDate: dayjs(appointmentInfo.startDate),
		endDate: dayjs(appointmentInfo.endDate),
	});

	const [searchedPatients, setSearchedPatients] = useState(
		[]
	);

	const [current, setCurrent] = useState(0);
	const [doctorsInfo, setDoctorsInfo] = useState([]);
	const { Step } = Steps;

	const next = (skipSteps) => {
		setCurrent(current + skipSteps);
	};
	const prev = () => {
		if (current === 1) {
			setSearchedPatients([]);
			setCurrent(0);
		} else {
			if (searchedPatients.length > 0) {
				setCurrent(current - 1);
			} else {
				setCurrent(current - 2);
			}
		}
	};
	function getDoctors() {
		const api = new Api();
		api
			.init()
			.get('/protected/users/doctors')
			.then((doctorsInfo) => {
				if (!empty(doctorsInfo)) {
					setDoctorsInfo(doctorsInfo.data);
				} else {
					message.error(
						'No Doctors found. Please add a Doctor in the Database'
					);
				}
			})
			.catch((err) => {
				console.log(err);
				message.error(
					'There was some error getting all Doctor Information. Please try again later'
				);
			});
	}
	const { Title } = Typography;
	// return !patientDetails.filled ? (
	useEffect(() => {
		getDoctors();
	}, []);

	return (
		<>
			<Steps current={current}>
				<Step key={0} title={'Search Patient'} />
				{searchedPatients.length === 0 ? null : (
					<Step key={1} title={'Select Patient'} />
				)}

				<Step key={2} title={'Add Appointment'} />
			</Steps>
			<div className='steps-content'>
				<GetSteps
					patientSearchFormValues={patientSearchFormValues}
					currentStep={current}
					setSearchedPatients={setSearchedPatients}
					searchedPatients={searchedPatients}
					setPatientDetails={setPatientDetails}
					next={next}
					patientDetails={patientDetails}
					setPatientSearchFormValues={
						setPatientSearchFormValues
					}
					onModalClose={onModalClose}
					doctorsInfo={doctorsInfo}
				/>
			</div>
			<div className='steps-action'>
				{current > 0 && (
					<Button
						style={{ margin: '0 8px' }}
						onClick={() => prev()}>
						Previous
					</Button>
				)}
			</div>
		</>
	);
}
