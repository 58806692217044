import React, { useEffect, useState } from 'react';
import {
	Timeline,
	Space,
	Typography,
	message,
	Tag,
	Tooltip,
} from 'antd';
import { Paper, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import dayjs from 'dayjs';
import PatientDiagnosisTreatmentModal from './PatientDiagnosisTreatmentModal';
import Api from '../../helpers/api';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const useStyles = makeStyles((theme) => ({
	timelinePaper: {
		margin: '0 auto',
		padding: theme.spacing(1),
		textAlign: 'center',
	},
}));
export default function PatientAppointmentHistory({
	patient_id,
}) {
	const { Text } = Typography;
	const classes = useStyles();
	const [appointmentInfo, setAppointmentInfo] = useState(
		[]
	);
	const [modalVisibility, setModalVisibility] =
		useState(false);
	const [openAppointment, setOpenAppointment] = useState(
		{}
	);
	const loadAppointmnetInfo = () => {
		const api = new Api();
		api
			.init()
			.get(
				'/protected/appointments/getappointmentsfrompatientid',
				{
					params: {
						patientId: patient_id,
					},
				}
			)
			.then((appointments) => {
				
				setAppointmentInfo(appointments.data);
			})
			.catch((err) => {
				message.error('Could not load appointments');
			});
	};
	useEffect(() => {
		loadAppointmnetInfo();
	}, []);
	const getShortVersion = (count, longString) => {
		const start = longString.substring(0, count);
		const end = longString.substring(
			count,
			longString.length
		);

		return { start, end };
	};

	return (
		<>
			<Timeline
				mode={'alternate'}
				style={{
					padding: '8px',
				}}>
				{appointmentInfo.length > 0 ? (
					appointmentInfo.map((appointment) => (
						<Timeline.Item
							key={appointment._id}
							label={dayjs(
								appointment.appointmentStartDateTime
							).format('DD-MM-YYYY hh:mm a')}>
							<Paper
								elevation={3}
								className={classes.timelinePaper}
								style={{ backgroundColor: '#F4F9F9' }}
								onClick={() => {
									setModalVisibility(true);
									setOpenAppointment(appointment);
								}}>
								<Space direction='vertical'>
									{appointment.appointmentDiagnosis
										.length === 0 ? (
										<div>
											No Diagnosis added to Appointment
										</div>
									) : (
										<Text
											strong
											style={{ width: 250 }}
											type='secondary'
											keyboard
											className='treatment-text'>
											{' '}
											{`${
												getShortVersion(
													40,
													appointment.appointmentDiagnosis.join(
														' | '
													)
												).start
											} ...`}
										</Text>
									)}
									<Divider />
									{appointment.appointmentTreatmentDone
										.length === 0 ? (
										<div>
											No Treatment added to Appointment
										</div>
									) : (
										<Text
											strong
											style={{ width: 250 }}
											type='secondary'
											keyboard
											className='treatment-text'>
											{' '}
											{`${
												getShortVersion(
													40,
													appointment.appointmentTreatmentDone.join(
														' | '
													)
												).start
											} ...`}
										</Text>
									)}
								</Space>
							</Paper>
						</Timeline.Item>
					))
				) : (
					<div>No Appointments</div>
				)}
			</Timeline>
			<PatientDiagnosisTreatmentModal
				loadAppointmnetInfo={loadAppointmnetInfo}
				modalVisibility={modalVisibility}
				setModalVisibility={setModalVisibility}
				setOpenAppointment={setOpenAppointment}
				openAppointment={openAppointment}
			/>
		</>
	);
}
