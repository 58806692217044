import React, { useState } from 'react';
import {
	Grid,
	makeStyles,
	Paper,
	Button,
	ButtonGroup,
	Checkbox,
	FormLabel,
	FormControl,
	FormControlLabel,
	FormGroup,
} from '@material-ui/core';
import { Tag } from 'antd';
import { Icon } from '@iconify/react';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

export default function PermanentTeeth({
	permanentTeeth,
	setPermanentTeeth,
}) {
	const classes = useStyles();
	const { CheckableTag } = Tag;
	const toothSelected = (toothNumber, value) => {
		setPermanentTeeth({
			...permanentTeeth,
			[toothNumber]: value,
		});
	};

	const quadrants = [
		{
			name: 'Upper Right',
			val: [18, 17, 16, 15, 14, 13, 12, 11],
		},
		{
			name: 'Upper Left',
			val: [21, 22, 23, 24, 25, 26, 27, 28],
		},
		{
			name: 'Lower Right',
			val: [48, 47, 46, 45, 44, 43, 42, 41],
		},
		{
			name: 'Lower Left',
			val: [31, 32, 33, 34, 35, 36, 37, 38],
		},
	];

	return (
		<Grid container spacing={3}>
			{quadrants.map((quadrant) => (
				<Grid item xs={6} key={quadrant.name}>
					<Paper
						className={classes.paper}
						style={{ backgroundColor: '#D3D3D3' }}>
						{/* <CheckableTag key={18} checked={false}>
							<Icon icon='mdi:tooth' color='white' />
						</CheckableTag> */}
						<FormControl
							component='fieldset'
							sx={{ m: 3 }}
							variant='filled'>
							<FormLabel component='legend'>
								{quadrant.name}
							</FormLabel>
							<FormGroup
								row
								style={{ padding: '0px', margin: '0px' }}>
								{quadrant.val.map((toothNumber) => (
									<FormControlLabel
										key={toothNumber}
										label={toothNumber}
										labelPlacement='bottom'
										control={
											<Checkbox
												size='medium'
												checked={
													permanentTeeth[toothNumber]
												}
												onChange={(e) => {
													toothSelected(
														toothNumber,
														e.target.checked
													);
												}}
												icon={
													<Icon
														icon='mdi:tooth'
														color='white'
													/>
												}
												checkedIcon={
													<Icon icon='mdi:tooth' />
												}
											/>
										}
									/>
								))}
							</FormGroup>
						</FormControl>{' '}
					</Paper>
				</Grid>
			))}
		</Grid>
	);
}
