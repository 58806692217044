import React, { FunctionComponent } from 'react';
import {
	Route,
	Switch,
	BrowserRouter,
} from 'react-router-dom';
import LoginLanding from '../Components/Login/LoginLanding';
import PatientInfo from '../Components/Patients/PatientInfo';
import SearchPatient from '../Components/Patients/SearchPatient';
// import Login from "../Components/Login/Login"
import AppContainer from '../AppContainer';
import Home from '../Components/Home/Home';
import Users from '../Components/Users/Users';
import { RouteComponentProps } from 'react-router';

// type Props = { component: FunctionComponent } & RouteComponentProps;

// type route = { path: string; name: string, roles: Array<string>, component: FunctionComponent<Props> };

const Routes = [
	{
		path: '/home',
		name: 'home',
		roles: ['Staff', 'Admin', 'Doctor'],
		target: null,
		component: Home,
	},
	{
		// name: `Patient Details`,
		path: '/patient/:patient_id',
		component: PatientInfo,
		target: '_blank',
		roles: ['Staff', 'Admin', 'Doctor'],
	},
	{
		name: 'Search Patients',
		path: '/patient',
		component: SearchPatient,
		target: '_blank',
		roles: ['Staff', 'Admin', 'Doctor'],
	},
	{
		name: 'Manage Users',
		path: '/users',
		component: Users,
		target: '_black',
		roles: ['Admin'],
	},
];

const renderRoutes = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/login' component={LoginLanding} />
			<Route path='/' component={AppContainer} />
		</Switch>
	</BrowserRouter>
);

export { Routes, renderRoutes };
