import {
	TOGGLE_APPOINTMENT_MODAL,
	CHANGE_CALENDAR_VIEW,
	GET_CALENDAR_DATA,
	APPOINTMENT_DATA_ERROR,
} from './appointmentTypes';

const initialState = {
	modalIsVisible: false,
	currentView: 'day',
	calendarData: [],
	calendarDataError: {
		show: false,
		message: '',
	},
};

export default function appointmentReducer(
	state = initialState,
	action
) {
	switch (action.type) {
		case TOGGLE_APPOINTMENT_MODAL:
			return {
				...state,
				modalIsVisible: !state.modalIsVisible,
			};
		case CHANGE_CALENDAR_VIEW:
			if (
				(action.payload === 'month') |
				(action.payload === 'week') |
				(action.payload === 'day')
			) {
				return { ...state, currentView: action.payload };
			} else {
				return {
					...state,
				};
			}
		case GET_CALENDAR_DATA:
			return {
				...state,
				calendarData: action.payload,
			};
		case APPOINTMENT_DATA_ERROR:
			return {
				...state,
				calendarDataError: {
					show: !state.calendarDataError.show,
					message: action.payload,
				},
			};
		default:
			return state;
	}
}
